<template>
  <v-chart class="chart" :option="optionReder" autoresize />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  GridComponent,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
])

export default {
  name: 'GeneralChart',

  props: {},

  components: {
    VChart,
  },

  data() {
    return {
      unity: this.$store.getters.unity,
      optionReder: {},
      chartData: null,
    }
  },

  methods: {
    async getGeneralsInfos() {
      try {
        this.financial = await this.$store.dispatch(
          'getFinancialGeneral',
          this.unity,
        )
        this.chartData = [
          {
            name: 'Em Aberto',
            value: this.financial.debtorValue,
            money: this.financial.debtorValueMoney,
            itemStyle: {
              color: '#f58646',
            },
          },
          {
            name: 'Pago',
            value: this.financial.paidValue,
            money: this.financial.paidValueMoney,
            itemStyle: {
              color: '#247297',
            },
          },
        ]
        this.generateOptionToChart()
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    generateOptionToChart() {
      this.optionReder = {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: '#fff',
          },
          trigger: 'item',
          formatter: (item) => {
            return `${item.name} <br>Valor: ${item.data.money} (${item.percent}%)`
          },
          position: 'inside',
        },
        legend: {
          bottom: '5%',
          left: 'center',
          textStyle: {
            color: '#959595',
          },
        },
        series: [
          {
            left: '3%',
            top: 10,
            right: '3%',
            bottom: 10,
            name: 'Posição Financeira',
            type: 'pie',
            radius: ['60%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.chartData,
          },
        ],
      }
    },
  },

  mounted() {
    this.getGeneralsInfos()
  },
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
